



































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import DashboardLayout from '@/components/DashboardLayout.vue';
import DashboardLink from '@/components/DashboardLink.vue';

export default Vue.extend({
  name: 'AdminDashboard',
  components: {
    AppMain,
    DashboardLayout,
    DashboardLink,
  },
});
